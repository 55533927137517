





























































































import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import FEATURES from '@/modules/common/constants/features.constant';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

enum CAR_ROUTES {
    RATES = '/rates',
    PARITY = '/parity',
    FLEET_DENSITY = '/fleet-density',
    EVENTS = '/events-manager',
}

@Component
export default class CarsSidebar extends Vue {
    @Inject(UserServiceS) userService!: UserService;
    @Inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) carsSharedService!: CarsSharedService;

    get currentRoutePath() {
        return this.$route.path;
    }

    get isDisabled() {
        if (!this.userService.currentCompany) {
            return false;
        }
        return false;
    }

    get primaryColor() {
        if (!this.carsSharedService.theme) {
            return '';
        }

        return this.carsSharedService.theme.primaryColor;
    }

    get secondaryColor() {
        if (!this.carsSharedService.theme) {
            return '';
        }

        return this.carsSharedService.theme.secondaryColor;
    }

    mouseOver(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target) {
            return;
        }

        const child = target.querySelector('.icon-car') as HTMLElement;
        if (child && this.secondaryColor !== null) {
            child.style.color = this.secondaryColor;
        }

        if (target.classList.contains('active-link') || !target.classList.contains('menu__link')) {
            return;
        }

        if (target.classList.contains('disabled-link')) {
            target.style.backgroundColor = '#555555';
        } else {
            target.style.backgroundColor = this.primaryColor || '';
        }
    }

    mouseLeave(event: Event) {
        const target = event.target as HTMLElement;
        if (!target) {
            return;
        }
        const child = target.querySelector('.icon-car') as HTMLElement;
        if (child && this.primaryColor !== null) {
            child.style.color = this.primaryColor;
        }

        if (target.classList.contains('active-link') || !target.classList.contains('menu__link')) {
            return;
        }

        target.style.backgroundColor = this.secondaryColor || '';
    }

    get iconColor() {
        if (!this.carsSharedService.theme) {
            return '';
        }

        return this.carsSharedService.theme.icons;
    }

    get feature() {
        return FEATURES.EVENTS;
    }

    get isBrokerVisibilityDisabled() {
        return !this.userService.currentCompany;
    }
}
